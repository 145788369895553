
export const AppMenus = {
    
	navbarTopRightItems: [],
	navbarTopLeftItems: [
  {
    "path": "/cars/home",
    "label": "Home",
    "icon": "home",
    "iconcolor": "",
    "target": "",
    "submenu": []
  }
],
	navbarSideLeftItems: [
  {
    "path": "/",
    "label": "Home",
    "icon": "home",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/customers/sot",
    "label": "Sot",
    "icon": "today",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/customers/vonesat",
    "label": "Me Vonesë",
    "icon": "add_alert",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/customers",
    "label": "Të Gjitha",
    "icon": "dns",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/cars/admin",
    "label": "Automjetet",
    "icon": "directions_car",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/customers/pickup_calendar",
    "label": "Kalendari",
    "icon": "date_range",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/locations",
    "label": "Lokacionet",
    "icon": "map",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/users",
    "label": "Përdoruesit",
    "icon": "account_circle",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/cars_history",
    "label": "Historiku",
    "icon": "search",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/brandcars",
    "label": "Brendet",
    "icon": "view_list",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/transactions",
    "label": "Financat",
    "icon": "euro_symbol",
    "iconcolor": "",
    "target": "",
    "submenu": []
  }
],
	AuditsTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Type",
    "align": "left",
    "sortable": false,
    "name": "user_type",
    "field": "user_type"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Event",
    "align": "left",
    "sortable": false,
    "name": "event",
    "field": "event"
  },
  {
    "label": "Auditable Type",
    "align": "left",
    "sortable": false,
    "name": "auditable_type",
    "field": "auditable_type"
  },
  {
    "label": "Auditable Id",
    "align": "left",
    "sortable": false,
    "name": "auditable_id",
    "field": "auditable_id"
  },
  {
    "label": "Old Values",
    "align": "left",
    "sortable": false,
    "name": "old_values",
    "field": "old_values"
  },
  {
    "label": "New Values",
    "align": "left",
    "sortable": false,
    "name": "new_values",
    "field": "new_values"
  },
  {
    "label": "Url",
    "align": "left",
    "sortable": false,
    "name": "url",
    "field": "url"
  },
  {
    "label": "Ip Address",
    "align": "left",
    "sortable": false,
    "name": "ip_address",
    "field": "ip_address"
  },
  {
    "label": "User Agent",
    "align": "left",
    "sortable": false,
    "name": "user_agent",
    "field": "user_agent"
  },
  {
    "label": "Tags",
    "align": "left",
    "sortable": false,
    "name": "tags",
    "field": "tags"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	BrandcarsTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Brandname",
    "align": "left",
    "sortable": false,
    "name": "brandname",
    "field": "brandname"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	CarsTableHeaderItems: [
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	modeItems: [    
{value: "Automatik", label: "Automatik"},
	{value: "Manual", label: "Manual"}
    ],
	statusItems: [    
{value: "available", label: "Available"},
	{value: "busy", label: "Busy"}
    ],
	derivatesItems: [    
{value: "Diesel", label: "Diesel"},
	{value: "Fuel", label: "Fuel"}
    ],
	car_insuranceItems: [    
{value: "Po", label: "Po"},
	{value: "Jo", label: "Jo"}
    ],
	seasonItems: [    
{value: "Verë", label: "Verë"},
	{value: "Dimër", label: "Dimër"}
    ],
	CarsTableHeader2Items: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Foto",
    "align": "left",
    "sortable": false,
    "name": "images",
    "field": "images"
  },
  {
    "label": "Price",
    "align": "left",
    "sortable": false,
    "name": "price",
    "field": "price"
  },
  {
    "label": "Description",
    "align": "left",
    "sortable": false,
    "name": "description",
    "field": "description"
  },
  {
    "label": "Mode",
    "align": "left",
    "sortable": false,
    "name": "mode",
    "field": "mode"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Location",
    "align": "left",
    "sortable": false,
    "name": "location",
    "field": "location"
  },
  {
    "label": "Car Insurance",
    "align": "left",
    "sortable": false,
    "name": "car_insurance",
    "field": "car_insurance"
  },
  {
    "label": "Data Expired",
    "align": "left",
    "sortable": false,
    "name": "data_expired",
    "field": "data_expired"
  },
  {
    "label": "Km Registration",
    "align": "left",
    "sortable": false,
    "name": "km_registration",
    "field": "km_registration"
  },
  {
    "label": "Season",
    "align": "left",
    "sortable": false,
    "name": "season",
    "field": "season"
  },
  {
    "label": "Extra Features",
    "align": "left",
    "sortable": false,
    "name": "extra_features",
    "field": "extra_features"
  },
  {
    "label": "Tablenumbers Car",
    "align": "left",
    "sortable": false,
    "name": "tablenumbers_car",
    "field": "tablenumbers_car"
  },
  {
    "label": "Passbook Nr",
    "align": "left",
    "sortable": false,
    "name": "passbook_nr",
    "field": "passbook_nr"
  },
  {
    "label": "Date Registration",
    "align": "left",
    "sortable": false,
    "name": "date_registration",
    "field": "date_registration"
  },
  {
    "label": "Kms",
    "align": "left",
    "sortable": false,
    "name": "kms",
    "field": "kms"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  },
  {
    "label": "Derivates",
    "align": "left",
    "sortable": false,
    "name": "derivates",
    "field": "derivates"
  },
  {
    "label": "Brande",
    "align": "left",
    "sortable": false,
    "name": "brande",
    "field": "brande"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	CarsTableHeader2Items: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Vetura",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Foto",
    "align": "left",
    "sortable": false,
    "name": "images",
    "field": "images"
  },
  {
    "label": "Çmimi",
    "align": "left",
    "sortable": false,
    "name": "price",
    "field": "price"
  },
  {
    "label": "Tabelat",
    "align": "left",
    "sortable": false,
    "name": "tablenumbers_car",
    "field": "tablenumbers_car"
  },
  {
    "label": "NR Shasis",
    "align": "left",
    "sortable": false,
    "name": "passbook_nr",
    "field": "passbook_nr"
  },
  {
    "label": "Skadimi regjistrimit",
    "align": "left",
    "sortable": false,
    "name": "data_expired",
    "field": "data_expired"
  },
  {
    "label": "KM",
    "align": "left",
    "sortable": false,
    "name": "kms",
    "field": "kms"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	Cars_HistoryTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Emri",
    "align": "left",
    "sortable": false,
    "name": "name_clients",
    "field": "name_clients"
  },
  {
    "label": "Mbiemri",
    "align": "left",
    "sortable": false,
    "name": "lastname_clients",
    "field": "lastname_clients"
  },
  {
    "label": "Data Marrjes",
    "align": "left",
    "sortable": false,
    "name": "date_pick",
    "field": "date_pick"
  },
  {
    "label": "Data Kthimit",
    "align": "left",
    "sortable": false,
    "name": "return_pick",
    "field": "return_pick"
  },
  {
    "label": "Para Dorzimit",
    "align": "left",
    "sortable": false,
    "name": "photo_pick",
    "field": "photo_pick"
  },
  {
    "label": "Pas Pranimit",
    "align": "left",
    "sortable": false,
    "name": "photo_return",
    "field": "photo_return"
  },
  {
    "label": "NR Klientit",
    "align": "left",
    "sortable": false,
    "name": "nr_customers",
    "field": "nr_customers"
  },
  {
    "label": "Përshkrimi",
    "align": "left",
    "sortable": false,
    "name": "description_return",
    "field": "description_return"
  },
  {
    "label": "Përdoruesi",
    "align": "left",
    "sortable": false,
    "name": "username_history",
    "field": "username_history"
  },
  {
    "label": "Koha Dorzimit",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Koha Pranimit",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  },
  {
    "label": "Statusi pranimit",
    "align": "left",
    "sortable": false,
    "name": "status_cars_check",
    "field": "status_cars_check"
  },
  {
    "label": "Cars Id",
    "align": "left",
    "sortable": false,
    "name": "cars_id",
    "field": "cars_id"
  },
  {
    "label": "Automjeti",
    "align": "left",
    "sortable": false,
    "name": "cars_name",
    "field": "cars_name"
  },
  {
    "label": "Foto",
    "align": "left",
    "sortable": false,
    "name": "cars_images",
    "field": "cars_images"
  },
  {
    "label": "Cmimi",
    "align": "left",
    "sortable": false,
    "name": "cars_price",
    "field": "cars_price"
  },
  {
    "label": "Cars Description",
    "align": "left",
    "sortable": false,
    "name": "cars_description",
    "field": "cars_description"
  },
  {
    "label": "Cars Mode",
    "align": "left",
    "sortable": false,
    "name": "cars_mode",
    "field": "cars_mode"
  },
  {
    "label": "Cars Status",
    "align": "left",
    "sortable": false,
    "name": "cars_status",
    "field": "cars_status"
  },
  {
    "label": "Cars Location",
    "align": "left",
    "sortable": false,
    "name": "cars_location",
    "field": "cars_location"
  },
  {
    "label": "Cars Car Insurance",
    "align": "left",
    "sortable": false,
    "name": "cars_car_insurance",
    "field": "cars_car_insurance"
  },
  {
    "label": "Cars Data Expired",
    "align": "left",
    "sortable": false,
    "name": "cars_data_expired",
    "field": "cars_data_expired"
  },
  {
    "label": "Cars Km Registration",
    "align": "left",
    "sortable": false,
    "name": "cars_km_registration",
    "field": "cars_km_registration"
  },
  {
    "label": "Cars Season",
    "align": "left",
    "sortable": false,
    "name": "cars_season",
    "field": "cars_season"
  },
  {
    "label": "Cars Extra Features",
    "align": "left",
    "sortable": false,
    "name": "cars_extra_features",
    "field": "cars_extra_features"
  },
  {
    "label": "Cars Tablenumbers Car",
    "align": "left",
    "sortable": false,
    "name": "cars_tablenumbers_car",
    "field": "cars_tablenumbers_car"
  },
  {
    "label": "Cars Passbook Nr",
    "align": "left",
    "sortable": false,
    "name": "cars_passbook_nr",
    "field": "cars_passbook_nr"
  },
  {
    "label": "Cars Date Registration",
    "align": "left",
    "sortable": false,
    "name": "cars_date_registration",
    "field": "cars_date_registration"
  },
  {
    "label": "Cars Kms",
    "align": "left",
    "sortable": false,
    "name": "cars_kms",
    "field": "cars_kms"
  },
  {
    "label": "Cars Date Created",
    "align": "left",
    "sortable": false,
    "name": "cars_date_created",
    "field": "cars_date_created"
  },
  {
    "label": "Cars Date Updated",
    "align": "left",
    "sortable": false,
    "name": "cars_date_updated",
    "field": "cars_date_updated"
  },
  {
    "label": "Cars Derivates",
    "align": "left",
    "sortable": false,
    "name": "cars_derivates",
    "field": "cars_derivates"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	status_cars_checkItems: [    
{value: "Ne rregull", label: "Ne Rregull"},
	{value: "E demtuar", label: "E demtuar"},
	{value: "E aksidentuar", label: "E aksidentuar"},
	{value: "Te tjera", label: "Te tjera"}
    ],
	CustomersTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Firstname",
    "align": "left",
    "sortable": false,
    "name": "firstname",
    "field": "firstname"
  },
  {
    "label": "Lastname",
    "align": "left",
    "sortable": false,
    "name": "lastname",
    "field": "lastname"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Drivers License",
    "align": "left",
    "sortable": false,
    "name": "drivers_license",
    "field": "drivers_license"
  },
  {
    "label": "Phone",
    "align": "left",
    "sortable": false,
    "name": "phone",
    "field": "phone"
  },
  {
    "label": "Country",
    "align": "left",
    "sortable": false,
    "name": "country",
    "field": "country"
  },
  {
    "label": "City",
    "align": "left",
    "sortable": false,
    "name": "city",
    "field": "city"
  },
  {
    "label": "Dropoff Location",
    "align": "left",
    "sortable": false,
    "name": "dropoff_location",
    "field": "dropoff_location"
  },
  {
    "label": "Pickup Date",
    "align": "left",
    "sortable": false,
    "name": "pickup_date",
    "field": "pickup_date"
  },
  {
    "label": "Dropoff Date",
    "align": "left",
    "sortable": false,
    "name": "dropoff_date",
    "field": "dropoff_date"
  },
  {
    "label": "Dropoff Time",
    "align": "left",
    "sortable": false,
    "name": "dropoff_time",
    "field": "dropoff_time"
  },
  {
    "label": "Pickup Time",
    "align": "left",
    "sortable": false,
    "name": "pickup_time",
    "field": "pickup_time"
  },
  {
    "label": "Pickup Location",
    "align": "left",
    "sortable": false,
    "name": "pickup_location",
    "field": "pickup_location"
  },
  {
    "label": "Total Days",
    "align": "left",
    "sortable": false,
    "name": "total_days",
    "field": "total_days"
  },
  {
    "label": "Amount",
    "align": "left",
    "sortable": false,
    "name": "amount",
    "field": "amount"
  },
  {
    "label": "Car",
    "align": "left",
    "sortable": false,
    "name": "car",
    "field": "car"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Verified By",
    "align": "left",
    "sortable": false,
    "name": "verified_by",
    "field": "verified_by"
  },
  {
    "label": "Verified On",
    "align": "left",
    "sortable": false,
    "name": "verified_on",
    "field": "verified_on"
  },
  {
    "label": "Returned On",
    "align": "left",
    "sortable": false,
    "name": "returned_on",
    "field": "returned_on"
  },
  {
    "label": "Collected By",
    "align": "left",
    "sortable": false,
    "name": "collected_by",
    "field": "collected_by"
  },
  {
    "label": "Extra Features",
    "align": "left",
    "sortable": false,
    "name": "extra_features",
    "field": "extra_features"
  },
  {
    "label": "Photo Card",
    "align": "left",
    "sortable": false,
    "name": "photo_card",
    "field": "photo_card"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  },
  {
    "label": "Nr Personal",
    "align": "left",
    "sortable": false,
    "name": "nr_personal",
    "field": "nr_personal"
  },
  {
    "label": "Discounts",
    "align": "left",
    "sortable": false,
    "name": "discounts",
    "field": "discounts"
  },
  {
    "label": "Payment Method",
    "align": "left",
    "sortable": false,
    "name": "payment_method",
    "field": "payment_method"
  },
  {
    "label": "Status Discount",
    "align": "left",
    "sortable": false,
    "name": "status_discount",
    "field": "status_discount"
  },
  {
    "label": "Statusi Voneses",
    "align": "left",
    "sortable": false,
    "name": "statusi_voneses",
    "field": "statusi_voneses"
  },
  {
    "label": "Pay Delay",
    "align": "left",
    "sortable": false,
    "name": "pay_delay",
    "field": "pay_delay"
  },
  {
    "label": "Pay Status",
    "align": "left",
    "sortable": false,
    "name": "pay_status",
    "field": "pay_status"
  },
  {
    "label": "Komenti",
    "align": "left",
    "sortable": false,
    "name": "komenti",
    "field": "komenti"
  },
  {
    "label": "Lirimi Vetures",
    "align": "left",
    "sortable": false,
    "name": "lirimi_vetures",
    "field": "lirimi_vetures"
  },
  {
    "label": "Cars Id",
    "align": "left",
    "sortable": false,
    "name": "cars_id",
    "field": "cars_id"
  },
  {
    "label": "Cars Name",
    "align": "left",
    "sortable": false,
    "name": "cars_name",
    "field": "cars_name"
  },
  {
    "label": "Cars Images",
    "align": "left",
    "sortable": false,
    "name": "cars_images",
    "field": "cars_images"
  },
  {
    "label": "Cars Price",
    "align": "left",
    "sortable": false,
    "name": "cars_price",
    "field": "cars_price"
  },
  {
    "label": "Cars Description",
    "align": "left",
    "sortable": false,
    "name": "cars_description",
    "field": "cars_description"
  },
  {
    "label": "Cars Mode",
    "align": "left",
    "sortable": false,
    "name": "cars_mode",
    "field": "cars_mode"
  },
  {
    "label": "Cars Status",
    "align": "left",
    "sortable": false,
    "name": "cars_status",
    "field": "cars_status"
  },
  {
    "label": "Cars Location",
    "align": "left",
    "sortable": false,
    "name": "cars_location",
    "field": "cars_location"
  },
  {
    "label": "Cars Car Insurance",
    "align": "left",
    "sortable": false,
    "name": "cars_car_insurance",
    "field": "cars_car_insurance"
  },
  {
    "label": "Cars Data Expired",
    "align": "left",
    "sortable": false,
    "name": "cars_data_expired",
    "field": "cars_data_expired"
  },
  {
    "label": "Cars Km Registration",
    "align": "left",
    "sortable": false,
    "name": "cars_km_registration",
    "field": "cars_km_registration"
  },
  {
    "label": "Cars Season",
    "align": "left",
    "sortable": false,
    "name": "cars_season",
    "field": "cars_season"
  },
  {
    "label": "Cars Extra Features",
    "align": "left",
    "sortable": false,
    "name": "cars_extra_features",
    "field": "cars_extra_features"
  },
  {
    "label": "Cars Tablenumbers Car",
    "align": "left",
    "sortable": false,
    "name": "cars_tablenumbers_car",
    "field": "cars_tablenumbers_car"
  },
  {
    "label": "Cars Passbook Nr",
    "align": "left",
    "sortable": false,
    "name": "cars_passbook_nr",
    "field": "cars_passbook_nr"
  },
  {
    "label": "Cars Date Registration",
    "align": "left",
    "sortable": false,
    "name": "cars_date_registration",
    "field": "cars_date_registration"
  },
  {
    "label": "Cars Kms",
    "align": "left",
    "sortable": false,
    "name": "cars_kms",
    "field": "cars_kms"
  },
  {
    "label": "Cars Date Created",
    "align": "left",
    "sortable": false,
    "name": "cars_date_created",
    "field": "cars_date_created"
  },
  {
    "label": "Cars Date Updated",
    "align": "left",
    "sortable": false,
    "name": "cars_date_updated",
    "field": "cars_date_updated"
  },
  {
    "label": "Cars Derivates",
    "align": "left",
    "sortable": false,
    "name": "cars_derivates",
    "field": "cars_derivates"
  },
  {
    "label": "Cars Brande",
    "align": "left",
    "sortable": false,
    "name": "cars_brande",
    "field": "cars_brande"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	Extra_FeaturesTableHeaderItems: [
  {
    "label": "Karakteristika shtesë",
    "align": "left",
    "sortable": false,
    "name": "feature",
    "field": "feature"
  },
  {
    "label": "Çmimi",
    "align": "left",
    "sortable": false,
    "name": "price",
    "field": "price"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	LocationsTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Emri Lokacionit",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	Model_Has_PermissionsTableHeaderItems: [
  {
    "label": "Permission Id",
    "align": "left",
    "sortable": false,
    "name": "permission_id",
    "field": "permission_id"
  },
  {
    "label": "Model Type",
    "align": "left",
    "sortable": false,
    "name": "model_type",
    "field": "model_type"
  },
  {
    "label": "Model Id",
    "align": "left",
    "sortable": false,
    "name": "model_id",
    "field": "model_id"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	Model_Has_RolesTableHeaderItems: [
  {
    "label": "Role Id",
    "align": "left",
    "sortable": false,
    "name": "role_id",
    "field": "role_id"
  },
  {
    "label": "Model Type",
    "align": "left",
    "sortable": false,
    "name": "model_type",
    "field": "model_type"
  },
  {
    "label": "Model Id",
    "align": "left",
    "sortable": false,
    "name": "model_id",
    "field": "model_id"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	OrdersTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Pickup Location",
    "align": "left",
    "sortable": false,
    "name": "pickup_location",
    "field": "pickup_location"
  },
  {
    "label": "Dropoff Location",
    "align": "left",
    "sortable": false,
    "name": "dropoff_location",
    "field": "dropoff_location"
  },
  {
    "label": "Pickup Date",
    "align": "left",
    "sortable": false,
    "name": "pickup_date",
    "field": "pickup_date"
  },
  {
    "label": "Dropoff Date",
    "align": "left",
    "sortable": false,
    "name": "dropoff_date",
    "field": "dropoff_date"
  },
  {
    "label": "Dropoff Time",
    "align": "left",
    "sortable": false,
    "name": "dropoff_time",
    "field": "dropoff_time"
  },
  {
    "label": "Pickup Time",
    "align": "left",
    "sortable": false,
    "name": "pickup_time",
    "field": "pickup_time"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	PermissionsTableHeaderItems: [
  {
    "label": "",
    "align": "left",
    "sortable": false,
    "name": "masterdetailbtn",
    "field": ""
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Guard Name",
    "align": "left",
    "sortable": false,
    "name": "guard_name",
    "field": "guard_name"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	Role_Has_PermissionsTableHeaderItems: [
  {
    "label": "Permission Id",
    "align": "left",
    "sortable": false,
    "name": "permission_id",
    "field": "permission_id"
  },
  {
    "label": "Role Id",
    "align": "left",
    "sortable": false,
    "name": "role_id",
    "field": "role_id"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	TransactionsTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Totali",
    "align": "left",
    "sortable": false,
    "name": "total",
    "field": "total"
  },
  {
    "label": "ID e Përdoruesit",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Data e Krijimit",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Data e Përditësimit",
    "align": "left",
    "sortable": false,
    "name": "updated_date",
    "field": "updated_date"
  },
  {
    "label": "Zbritje",
    "align": "left",
    "sortable": false,
    "name": "discount",
    "field": "discount"
  },
  {
    "label": "Vonesë",
    "align": "left",
    "sortable": false,
    "name": "delay",
    "field": "delay"
  },
  {
    "label": "ID e Rezervimit",
    "align": "left",
    "sortable": false,
    "name": "cid",
    "field": "cid"
  },
  {
    "label": "Shuma Totale",
    "align": "left",
    "sortable": false,
    "name": "Mbledhja",
    "field": "Mbledhja"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	UsersTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Foto Profilit",
    "align": "left",
    "sortable": false,
    "name": "profile_picture",
    "field": "profile_picture"
  },
  {
    "label": "Emri dhe Mbiemri",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Emri i përdoruesit",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Para në dorë",
    "align": "left",
    "sortable": false,
    "name": "sales",
    "field": "sales"
  },
  {
    "label": "Para në bankë",
    "align": "left",
    "sortable": false,
    "name": "salesbank",
    "field": "salesbank"
  },
  {
    "label": "Date Created",
    "align": "left",
    "sortable": false,
    "name": "date_created",
    "field": "date_created"
  },
  {
    "label": "Date Updated",
    "align": "left",
    "sortable": false,
    "name": "date_updated",
    "field": "date_updated"
  },
  {
    "label": "Lokacionet",
    "align": "left",
    "sortable": false,
    "name": "location",
    "field": "location"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	Customers_ListStaticMenuItems: [
  {
    "path": "/customers",
    "label": "Të Gjitha",
    "icon": "dns",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/customers/vonesat",
    "label": "Me Vonesë",
    "icon": "add_alert",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/customers/sot",
    "label": "Sot",
    "icon": "today",
    "iconcolor": "",
    "target": "",
    "submenu": []
  }
],

    exportFormats: {
        print: {
			label: 'Print',
			color: 'blue',
            icon: 'print',
            id: 'print',
            ext: '',
        },
        pdf: {
			label: 'Pdf',
			color: 'red',
            icon: 'picture_as_pdf',
            id: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
			color: 'green',
            icon: 'grid_on',
            id: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
			color: 'teal',
            icon: 'grid_on',
            id: 'csv',
            ext: 'csv',
        },
    },
    
}